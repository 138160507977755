.glassMagnifier {
    max-width: 80%;
    margin: 0 auto;
}

.glassMagnifier > div > div > img {
 max-width: none;
}

.__reakit-portal {
    z-index: 51;
    position: relative;
}

.table__tbody td {
    border: 1px solid #F2F4F5;
}
