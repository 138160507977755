.auth-code {
  .auth-code-wrapper > div {
    display: flex;
    justify-content: space-between;
  }

  input {
    border: 1px solid #EBEAED;
    transition: all .15s ease-in-out;
    -webkit-box-shadow: 0 0 0 30px white inset !important;

    &:focus {
      outline: none;
      border: 1px solid #157BFA;
      box-shadow: 0 0 4px rgba(21, 123, 250, 0.4);
    }
  }

  &.error {
    input {
      border: 1px solid #FF4040;
    }
  }
}
