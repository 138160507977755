.content {
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  opacity: 0;
  transform: translate3d(0, -60%, 0);
}
.content[data-enter] {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.content[data-leave] {
  // Uncomment below to have a different leave animation
  // transform: translate3d(0, 100%, 0);
}
